// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e1f5f6;
$primaryMain: #007096;
$primaryDark: #004b6d;
$primaryMidDark: #00405d;
$primaryExtraDark: #002739;
$primary200: #bbe1ee;
$primary600: #3aaecd;
$primarySaturated: #25ceff;
$primary800: #1da1c7;

// secondary
$secondaryLight: #fff1d8;
$secondaryMain: #fe7f00;
$secondaryDark: #fe7f00;
$secondary200: #ffe3b1;
$secondary800: #ffa800;

// success Colors
$successLight: #adf6cf;
$success200: #69f0af;
$successMain: #07962d;
$successDark: #018522;

// error
$errorLight: #e9879c;
$errorAltMain: #ff5874;
$errorMain: #aa0e3e;
$errorDark: #96073b;

// orange
$orangeLight: #ffe3b1;
$orangeAltMain: #ffc85f;
$orangeMain: #ffa800;
$orangeDark: #ff7f00;

// warning
$warningLight: #ffe3b1;
$warningAltMain: #ffc85f;
$warningMain: #ffa800;
$warningDark: #ff7f00;

// grey
$grey0: #ffffff;
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #c9c9c9;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #575757;
$grey800: #242424;
$grey900: #131313;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #002739; // level 3
$darkPaper: #112836; // level 4

// dark 800 & 900
$darkLevel1: #1f1f1f; // level 1
$darkLevel2: #242424; // level 2

// primary dark
$darkPrimaryLight: #e1f5f6;
$darkPrimaryMain: #007096;
$darkPrimaryDark: #005277;
$darkPrimary200: #bbe1ee;
$darkPrimary800: #1da1c7;
$darkPrimaryExtraDark: #002739;

// secondary dark
$darkSecondaryLight: #fff1d8;
$darkSecondaryMain: #fe7f00;
$darkSecondaryDark: #fe7f00;
$darkSecondary200: #ffe3b1;
$darkSecondary800: #ffa800;

// text variants
$darkTextTitle: #e1f5f6;
$darkTextPrimary: #83cfde;
$darkTextSecondary: #59bcd2;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primary600: $primary600;
    primarySaturated: $primarySaturated;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primaryMidDark: $primaryMidDark;
    primaryExtraDark: $primaryExtraDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryAltMain: $secondary800;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successAltMain: $success200;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorAltMain: $errorAltMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeAltMain: $orangeAltMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningAltMain: $warningAltMain;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey0: $grey0;
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey400: $grey400;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey800: $grey800;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimaryExtraDark: $darkPrimaryExtraDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
